<template>
  <v-autocomplete
    v-bind="$attrs"
    hide-details="auto"
    :items="timezones"
    item-text="fullname"
    item-value="name"
    v-model="timezone"
    :dense="dense"
    clear-icon="mdi-close-circle-outline"
    :clearable="!required"
    :rules="[required ? requiredRule() : []]"
    outlined
  >
    <template v-slot:item="{ item }">
      <div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 primary--text">
              {{ item.fullname }})
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import ct from 'countries-and-timezones'
export default {
  inheritAttrs: false,
  name: 'Timezone',
  props: {
    value: {
      type: String,
      default: 'UTC'
    },
    required: { type: Boolean, default: true },
    dense: { type: Boolean, default: true }
  },
  data: () => ({
    timezone: 'UTC'
  }),
  created() {
    this.timezone = this.myTz()
  },
  computed: {
    timezones() {
      return Object.values(ct.getAllTimezones())
        .filter((i) => {
          return (
            (i.name.includes('/') && !i.name.includes('Etc/')) ||
            i.name === 'UTC'
          )
        })
        .map((i) => {
          i.fullname = `${i.name} (${i.utcOffsetStr})`
          return i
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.timezone = val
      },
      immediate: true,
      deep: true
    },
    timezone: {
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style></style>
